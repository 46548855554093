/* eslint-disable max-classes-per-file */
import { Api, makeGetUrl } from '@/libraries/api'
import { AddressSchema } from './schema'

const AddressList = class {
  constructor() {
    this.query = ''
    this.parent_idx = null
  }

  set parentIdx(value) {
    this.parent_idx = value
  }

  async getList() {
    const url = `/admin/address/list`
    const params = {}
    if (this.parent_idx !== null) {
      params.parent_idx = this.parent_idx
    }
    const apiResult = await Api().get(makeGetUrl(url, params))
    const { items } = apiResult.data.data
    return {
      items: items.map((item) => new AddressSchema(item))
    }
  }
}

export default AddressList
