/* eslint-disable max-classes-per-file */
// import { Api } from '@/libraries/api'

export const AddressSchema = class {
  constructor(item = {}) {
    this.idx = item.idx || 0
    this.parent_idx = item.parent_idx || 0
    this.area = item.area || ''
    this.short_name = item.short_name || ''
    this.is_display = item.is_display || false
  }
}

export const AddressFormSchema = class {
  constructor(item = {}) {
    this.idx = item.idx || 0
    this.short_name = item.short_name || ''
    this.is_display = item.is_display || false
  }
}
