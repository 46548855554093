<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageTitleComponent title="주소 관리" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <InformationComponent
          type="info"
          :messages="[`* 특별, 광역시, 도를 클릭하면 해당 시/군을 확인할 수 있습니다.`,
                      `* 특별, 광역시, 도의 사용여부를 N 으로 하면 하위 시/군 의 표시가 Y 더라도 노출되지 않습니다.`,
                      `* 주소를 수정하고 포커스에서 벗어나거나, 노출여부를 스위치 UI를 클릭할 경우 자동 저장됩니다.`]"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-simple-table
          class="tbl elevation-2"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="item in headers"
                  :key="item.text"
                  :class="`text-${item.align}`"
                >
                  {{ item.text }}
                </th>
              </tr>
            </thead>
            <TbodyPreRender
              :is-loading="isLoading"
              :cols="headers.length"
              :items-length="items.length"
            />
            <tbody v-if="!isLoading">
              <tr
                v-for="item in items"
                :key="`address-item-${item.idx}`"
              >
                <td class="text-center pr-2">
                  <template v-if="item.parent_idx === 0">
                    <router-link :to="`/address?parent_idx=${item.idx}`">
                      {{ item.area }}
                    </router-link>
                  </template>
                  <template v-else>
                    {{ item.area }}
                  </template>
                </td>
                <td
                  class="text-center"
                >
                  <v-container fluid>
                    <v-row justify="center">
                      <v-col cols="auto">
                        <v-text-field
                          v-model="item.short_name"
                          dense
                          hide-details
                          outlined
                          label=""
                          required
                          @blur="updateItem(item)"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </td>
                <td
                  class="text-center"
                  style=""
                >
                  <v-container fluid>
                    <v-row justify="center">
                      <v-col cols="auto">
                        <v-switch
                          v-model="item.is_display"
                          hide-details
                          inset
                          :label="`${item.is_display ? 'Y' : 'N'}`"
                          class="mt-0"
                          @click="updateItem(item)"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                  <!-- {{ (item.is_display) ? 'Y' : 'N' }} -->
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageTitleComponent from '../_elements/title/PageTitle'
import InformationComponent from '../_elements/textbox/Information'
import TbodyPreRender from '../_elements/table/TbodyPreRender'
import Address from './model/address'
import AddressList from './model/addressList'

export default {
  components: {
    PageTitleComponent,
    InformationComponent,
    TbodyPreRender
  },
  data() {
    return {
      model: new AddressList(),
      isLoading: false,
      query: '',
      items: [],
      headers: [
        { text: '원본 주소', align: 'center' },
        { text: '수정된 주소', align: 'center' },
        { text: '사용여부', align: 'center' }
      ]
    }
  },
  created() {
    const params = this.$route.query
    if (params.parent_idx) {
      this.model.parentIdx = params.parent_idx
    }
    this.getList()
  },
  methods: {
    async getList() {
      this.isLoading = true
      const { items } = await this.model.getList()
      if (items) {
        this.items = items
      }
      this.isLoading = false
    },
    async updateItem(item) {
      const model = new Address()
      model.idx = item.idx
      model.shortName = item.short_name
      model.isDisplay = item.is_display
      const result = await model.update()
      if (result === true) {
        this.$bus.$emit('successMessage', '수정되었습니다')
      } else {
        this.$bus.$emit('successMessage', '서버 통신에 문제가 발생했습니다')
      }
    },
    async getAddress(item) {
      const address = await item.extra_info.getAddress()
      // console.log(address)
      return address
    }
  }
}
</script>

<style lang="scss" scoped>
.tbl {
  ::v-deep table {
    min-width: 500px;
  }
}
.theme--light.v-data-table {
  ::v-deep table > thead > tr > th {
    background-color: #EEEEEE;
  }
}
.v-skeleton-loader {
  ::v-deep .v-skeleton-loader__list-item {
    padding: 0px !important;
  }
}
</style>
