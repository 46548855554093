import { Api } from '@/libraries/api'
// import { Api, makeGetUrl } from '@/libraries/api'
import { AddressFormSchema } from './schema'

const Address = class {
  constructor() {
    this.item = new AddressFormSchema()
  }

  set idx(idx) {
    this.item.idx = idx
  }

  set shortName(shortName) {
    this.item.short_name = shortName
  }

  set isDisplay(isDisplay) {
    this.item.is_display = Boolean(isDisplay)
  }

  async update() {
    const url = `/admin/address/item/${this.item.idx}`
    const data = { ...this.item }
    const apiResult = await Api().put(url, data)
    const { result } = apiResult.data
    return result
  }
}

export default Address
